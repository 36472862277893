import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { UrlUtilityService } from '@shared/utilities/url-utility.service';

import { userGeneratedContentAttribute } from '@common/directives/user-generated-content/user-generated-content-attribute.constant';

/**
 * @description
 * Overrides the default behaviour for a tags when we compile html from external sources
 * (e.g. html module, rich text editors).
 *
 * This serves as more reliable replacement to the url-interceptor.service.
 * However we can't support links in SVGs with this as it causes them to go wild.
 * Until we stop using links in SVGs the original service has to remain in place.
 */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'a:not(.cc-svg-link)',
    template: '<ng-content></ng-content>',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkOverrideComponent {
    @HostListener('click', ['$event'])
    public onClick(event: MouseEvent): void {
        const href = (event.target as HTMLAnchorElement).href;

        if (!href) {
            return;
        }

        const isUserGenerated = !!(event.target as HTMLElement).closest(`[${userGeneratedContentAttribute}]`);

        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        this.urlUtilityService.open(href, {
            warnExternal: isUserGenerated
        });
    }

    constructor(private urlUtilityService: UrlUtilityService) {}
}
