import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren
} from '@angular/core';
import { BehaviorSubject, combineLatest, EMPTY, Observable, switchMap, throwError } from 'rxjs';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { ChatMessageComponent } from '@features/chat/components/chat-message/chat-message.component';
import { PersonHeaderActions } from '@shared/people/types/person-header-actions.type';
import { PersonHeaderAction } from '@shared/people/enums/person-header-action.enum';
import { fadeIn } from '@common/animations/animations';
import { Chat, ChatMessage, People } from '@shared/api';
import { ChatApiService } from '@features/chat/services/chat-api/chat-api.service';
import { PeopleApiService } from '@features/people/services/people-api.service';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { catchError, filter, map, shareReplay, startWith, take, tap, withLatestFrom } from 'rxjs/operators';
import { ChatService } from '@features/chat/services/chat/chat.service';
import { DateTime } from 'luxon';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { AppUserPageRoutes } from '@core/routing/constants/user-page-routes.constant';
import { AppPageRoutes } from '@core/routing/constants/app-page-routes.constant';

@Component({
    selector: 'cc-chat-detail',
    templateUrl: './chat-detail.component.html',
    styleUrls: ['./chat-detail.component.scss'],
    animations: [fadeIn()]
})
@UntilDestroy()
export class ChatDetailComponent implements OnInit, OnChanges {
    @ViewChildren('chatMessages')
    public chatMessages: QueryList<ChatMessageComponent>;

    @Input()
    public chatId: string;

    @Input()
    public displayHeader: boolean;

    @Output()
    public peerLoaded = new EventEmitter<People>();

    public loggedInUser$: Observable<People>;
    public initialising$: Observable<boolean>;
    public messages$: Observable<ChatMessage[]>;
    public peer$: Observable<People>;

    public headerActions: PersonHeaderActions = { [PersonHeaderAction.Chat]: false };

    private readonly timeBreak = 5;
    private chatIdSubject = new BehaviorSubject<Chat['id'] | null>(null);
    private newMessagesSubject = new BehaviorSubject<ChatMessage[]>([]);

    constructor(
        private authenticationFacadeService: AuthenticationFacadeService,
        private apiService: ChatApiService,
        private peopleApiService: PeopleApiService,
        private appCoreFacadeService: AppCoreFacadeService,
        private chatService: ChatService,
        private router: Router
    ) {}

    public ngOnInit(): void {
        this.messages$ = this.getMessages$();
        this.peer$ = this.getPeer$();

        this.loggedInUser$ = this.authenticationFacadeService.getAuthenticatedPerson();

        this.initialising$ = combineLatest({
            messages: this.messages$.pipe(startWith(null)),
            peer: this.peer$.pipe(startWith(null))
        }).pipe(
            map(({ messages, peer }) => !messages || !peer),
            catchError(() => {
                this.backToList();
                return EMPTY;
            })
        );

        this.chatService
            .getRealtimeMessages()
            .pipe(
                filter((message) => message.chat === this.chatId),
                untilDestroyed(this)
            )
            .subscribe((newMessage) => {
                this.chatService.markAsRead(this.chatId);

                this.newMessagesSubject.next([...this.newMessagesSubject.value, newMessage]);
            });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.chatId) {
            this.chatIdSubject.next(this.chatId);
            this.chatService.markAsRead(this.chatId);
            this.newMessagesSubject.next([]);
        }
    }

    public onSend(message: string): void {
        this.appCoreFacadeService
            .getAppName()
            .pipe(
                take(1),
                switchMap((appUrl) => this.apiService.sendMessage(appUrl, this.chatId, message))
            )
            .subscribe((newMessage) => this.newMessagesSubject.next([...this.newMessagesSubject.value, newMessage]));
    }

    public isTimeBreak(currentMessage: ChatMessage, previousMessage: ChatMessage): boolean {
        if (!previousMessage) {
            return true;
        }

        return (
            DateTime.fromISO(currentMessage.created).diff(DateTime.fromISO(previousMessage.created)).as('minutes') >
            this.timeBreak
        );
    }

    private getPeer$(): Observable<People> {
        return this.chatIdSubject.pipe(
            withLatestFrom(
                this.appCoreFacadeService.getAppName(),
                this.authenticationFacadeService.getAuthenticatedPerson()
            ),
            switchMap(([chatId, appUrl, loggedInUser]) =>
                this.apiService.getChat(appUrl, chatId).pipe(
                    map((chat) => chat.peers.find((peer) => peer.id !== loggedInUser.id)),
                    switchMap((peer) => {
                        if (!peer) {
                            return throwError(() => new Error('Peer not found'));
                        }

                        return this.peopleApiService.getPerson(appUrl, peer.id);
                    })
                )
            ),
            tap((peer) => this.peerLoaded.emit(peer)),
            shareReplay(1)
        );
    }

    private getMessages$(): Observable<ChatMessage[]> {
        return combineLatest({
            fromApi: this.chatIdSubject.pipe(
                withLatestFrom(this.appCoreFacadeService.getAppName()),
                switchMap(([chatId, appUrl]) => this.apiService.getMessages(appUrl, chatId))
            ),
            newMessages: this.newMessagesSubject
        }).pipe(
            map(({ fromApi, newMessages }) =>
                [...fromApi, ...newMessages].sort((a, b) => a.created.localeCompare(b.created))
            ),
            shareReplay(1)
        );
    }

    private backToList(): void {
        this.appCoreFacadeService
            .getAppName()
            .pipe(take(1))
            .subscribe((appUrl) => this.router.navigate([appUrl, AppPageRoutes.user, AppUserPageRoutes.chat]));
    }
}
