import { Injectable } from '@angular/core';
import { StorageService } from '@core/storage/services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class ChatStorageService {
    private readonly popupChatOpenKey = 'popup-chat-open';

    constructor(private storageService: StorageService) {}

    public shouldUsePopup(): boolean {
        return this.storageService.get(this.popupChatOpenKey) ?? false;
    }

    public setShouldUsePopup(usePopup: boolean): void {
        this.storageService.put(this.popupChatOpenKey, usePopup);
    }
}
