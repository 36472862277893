@if (
    {
        appUrl: appUrl$ | async,
        state: state$ | async,
        detailPeer: detailPeer$ | async,
        canVideoCall: canVideoCall$ | async
    };
    as data
) {
    <ion-toolbar class="chat-popup__header">
        <ion-buttons class="chat-popup__start-buttons" slot="start">
            @if (data.state.view === popupChatView.detail) {
                <ion-button
                    class="chat-popup__back-button"
                    [routerLink]="['/', data.appUrl, appPageRoutes.user, appUserRoutes.chat]"
                >
                    <i class="material-icons" slot="icon-only">chevron_left</i>
                </ion-button>
            }

            @if (data.state.view === popupChatView.list) {
                <div class="chat-popup__chat-icon">
                    <i class="material-icons">chat</i>
                </div>
            }
        </ion-buttons>

        <ion-title class="chat-popup__title">
            @if (data.state.view === popupChatView.list) {
                {{ 'CHAT_POPUP_TITLE' | translate }}
            }

            @if (data.state.view === popupChatView.detail && data.detailPeer) {
                <a
                    class="chat-popup__peer-name"
                    [routerLink]="['/', data.appUrl, appPageRoutes.person, data.detailPeer.id]"
                >
                    <cc-person-name excludes="title" [person]="data.detailPeer"></cc-person-name>
                </a>
            }
        </ion-title>

        <ion-buttons class="chat-popup__end-buttons" slot="end">
            @if (data.state.view === popupChatView.detail && data.canVideoCall) {
                <ion-button class="chat-popup__video-call-button" (click)="videoCall(data.detailPeer)">
                    <i class="material-icons" slot="icon-only">videocam</i>
                </ion-button>
            }

            <ion-button class="chat-popup__close-button" (click)="close()">
                <i class="material-icons" slot="icon-only">close</i>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>

    @if (data.state.view === popupChatView.list) {
        <cc-chat-list></cc-chat-list>
    }

    @if (data.state.view === popupChatView.detail) {
        <cc-chat-detail [chatId]="data.state.chatId" (peerLoaded)="onPeerLoaded($event)"></cc-chat-detail>
    }
}
