import { animate, AnimationTriggerMetadata, query, stagger, style, transition, trigger } from '@angular/animations';

export const fadeIn = (duration: number = 200): AnimationTriggerMetadata =>
    trigger('fadeIn', [transition('void => *', [style({ opacity: 0 }), animate(duration)])]);

export const fadeOut = (duration: number = 200): AnimationTriggerMetadata =>
    trigger('fadeOut', [transition('* => void', [animate(duration, style({ opacity: 0 }))])]);

export const slideValueChange = (duration: number = 200): AnimationTriggerMetadata =>
    trigger('slideValueChange', [
        transition(':increment', [
            style({ transform: 'translateX(110%) scale(0.9)', opacity: 0 }),
            animate(`${duration}ms ease-out`, style('*'))
        ]),
        transition(':decrement', [
            style({ transform: 'translateX(-110%) scale(0.9)', opacity: 0 }),
            animate(`${duration}ms ease-out`, style('*'))
        ])
    ]);

export const staggerFade = (staggerDuration: number = 150, fadeDuration: number = 500): AnimationTriggerMetadata =>
    trigger('staggerFade', [
        transition(':enter', [
            query(
                ':enter',
                [
                    style({ opacity: 0 }),
                    stagger(`${staggerDuration}ms`, [animate(`${fadeDuration}ms`, style({ opacity: 1 }))])
                ],
                { optional: true }
            )
        ])
    ]);

export const slideInOutTop = (): AnimationTriggerMetadata =>
    trigger('slideInOutTop', [
        transition(':enter', [
            style({ transform: 'translateY(-100%)', opacity: 0 }),
            animate('300ms ease-in-out', style({ transform: 'translateY(0%) scale(1)', opacity: 1 }))
        ]),
        transition(':leave', [animate('300ms ease-in-out', style({ transform: 'translateY(-100%)', opacity: 0 }))])
    ]);

export const slideInOut = trigger('slideInOut', [
    transition(':enter', [
        style({ transform: 'translateX(110%)' }),
        animate('750ms ease-in-out', style({ transform: 'translateX(0%)' }))
    ]),
    transition(':leave', [animate('500ms ease-in-out', style({ transform: 'translateX(110%)' }))])
]);

// Used to delay displaying for 400ms. Useful for loading spinners or skeletons where you don't want a flickering effect.
export const delayFadeIn = (delay: number = 400, duration: number = 1000): AnimationTriggerMetadata =>
    trigger('delayFadeIn', [transition(':enter', [style({ opacity: 0 }), animate(`${duration}ms ${delay}ms`)])]);

export const slideInOutFromBottom = (duration = 200): AnimationTriggerMetadata =>
    trigger('slideInOutFromBottom', [
        transition(':enter', [
            style({ transform: 'translateY(100%)', opacity: 0 }),
            animate(`${duration}ms ease-in-out`, style({ transform: 'translateY(0%)', opacity: 1 }))
        ]),
        transition(':leave', [
            animate(`${duration}ms ease-in-out`, style({ transform: 'translateY(100%)', opacity: 0 }))
        ])
    ]);
