import { Injectable } from '@angular/core';
import { Chat, ChatMessage } from '@api';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiSettings } from '@shared/settings/api-settings.constant';
import { PaginatedResponse } from '@shared/pagination/models/paginated-response.model';
import { PaginationState } from '@shared/pagination/models/pagination-state.model';

@Injectable({
    providedIn: 'root'
})
export class ChatApiService {
    constructor(private httpClient: HttpClient) {}

    public createChat(appUrl: string, personId: number, loggedInUserId: number): Observable<Chat> {
        return this.httpClient.post<Chat>(ApiSettings.fullUrl(appUrl, ChatEndpoints.chats), {
            app: appUrl,
            peers: [personId, loggedInUserId]
        });
    }

    public sendMessage(appUrl: string, chatId: string, text: string): Observable<ChatMessage> {
        return this.httpClient.post<ChatMessage>(ApiSettings.fullUrl(appUrl, ChatEndpoints.messages(chatId)), {
            app: appUrl,
            chat: chatId,
            text
        });
    }

    public markRead(appUrl: string, chatId: string): Observable<any> {
        return this.httpClient.post(ApiSettings.fullUrl(appUrl, ChatEndpoints.markRead(chatId)), null);
    }

    public getChat(appName: string, chatId: string): Observable<Chat> {
        const url = ApiSettings.fullUrl(appName, ChatEndpoints.chat(chatId));
        return this.httpClient.get<Chat>(url);
    }

    public getMessages(appName: string, chatId: string): Observable<ChatMessage[]> {
        const url = ApiSettings.fullUrl(appName, ChatEndpoints.messages(chatId));
        return this.httpClient.get<ChatMessage[]>(url);
    }

    public getPaginatedChats(appName: string, params: PaginationState): Observable<PaginatedResponse<Chat>> {
        const url = ApiSettings.fullUrl(appName, ChatEndpoints.paginatedChats);
        const limit = params.limit;
        const offset = (params.page - 1) * limit;
        const search = params.search ?? '';

        return this.httpClient.get<PaginatedResponse<Chat>>(url, {
            params: {
                limit,
                offset,
                search
            }
        });
    }
}

export const ChatEndpoints = {
    chats: '/chats/',
    paginatedChats: '/chats/table/?expand=peers',
    messages: (chatId: string) => `/chats/${chatId}/messages/`,
    chat: (chatId: string) => `/chats/${chatId}/?expand=peers`,
    markRead: (chatId: string) => `/chats/${chatId}/mark-read/`
};
