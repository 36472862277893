import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AutoLineClampDirective } from './auto-line-clamp/auto-line-clamp.directive';
import { ButtonAccessibilityDirective } from './button-accessibility/button-accessibility.directive';
import { CssPropsDirective } from './css-props/css-props.directive';
import { ExactClickDirective } from './exact-click/exact-click.directive';
import { ExternalHrefDirective } from './external-href/external-href.directive';
import { HorizontalScrollDirective } from './horizontal-scroll/horizontal-scroll.directive';
import { IfAuthenticatedDirective } from './if-authenticated/if-authenticated.directive';
import { IfNotAuthenticatedDirective } from './if-not-authenticated/if-not-authenticated.directive';
import { IntersectionObserverDirective } from './intersection-observer/intersection-observer.directive';
import { IntervalChangeDetectionDirective } from './interval-change-detection/interval-change-detection.directive';
import { ScrollbarDirective } from './scroll-bar/scroll-bar.directive';
import { TrackByIdDirective } from './track-by-id/track-by-id.directive';
import { UserGeneratedContentDirective } from '@common/directives/user-generated-content/user-generated-content.directive';

@NgModule({
    declarations: [
        IfAuthenticatedDirective,
        IfNotAuthenticatedDirective,
        AutoLineClampDirective,
        CssPropsDirective,
        ExactClickDirective,
        ExternalHrefDirective,
        IntersectionObserverDirective,
        IntervalChangeDetectionDirective,
        ScrollbarDirective,
        ButtonAccessibilityDirective,
        HorizontalScrollDirective,
        TrackByIdDirective,
        UserGeneratedContentDirective
    ],
    imports: [CommonModule],
    exports: [
        IfAuthenticatedDirective,
        IfNotAuthenticatedDirective,
        AutoLineClampDirective,
        CssPropsDirective,
        ExactClickDirective,
        ExternalHrefDirective,
        IntersectionObserverDirective,
        IntervalChangeDetectionDirective,
        ScrollbarDirective,
        ButtonAccessibilityDirective,
        HorizontalScrollDirective,
        TrackByIdDirective,
        UserGeneratedContentDirective
    ]
})
export class CommonDirectivesModule {}
