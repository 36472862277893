import { IOptions } from 'sanitize-html';

export const DefaultHtmlSanitiserOptions: IOptions = {
    allowedTags: ['a', 'b', 'strong', 'ul', 'p', 'span', 'cc-tag', 'cc-custom-html-element', 'img', 'div'],
    allowedStyles: {
        '*': {
            'font-size': [/^\d+(?:px|em|%)$/]
        }
    },
    allowedAttributes: {
        'cc-tag': ['data-tagged-ct', 'data-tagged-id'],
        a: ['href'],
        'cc-custom-html-element': [{ name: 'type', values: ['lightbox-image'] }],
        div: [{ name: 'class', values: ['images'] }],
        img: ['src']
    }
};
