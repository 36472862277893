import { Directive, HostBinding } from '@angular/core';
import { userGeneratedContentAttribute } from '@common/directives/user-generated-content/user-generated-content-attribute.constant';

@Directive({
    selector: '[ccUserGeneratedContent]'
})
export class UserGeneratedContentDirective {
    @HostBinding(`attr.${userGeneratedContentAttribute}`)
    public userGeneratedContent = '';
}
