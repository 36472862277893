<ion-header
    *ngIf="{
        menuButtonType: menuButtonType$ | async,
        showMenuButton: showMenuButton$ | async,
        canGoBack: canGoBack$ | async,
        popupOpen: popupOpen$ | async,
        chatBadge: chatBadge$ | async,
        alertsBadge: alertsBadge$ | async,
        loggedInUser: loggedInUser$ | async,
        canChat: canChat$ | async
    } as toolbarData"
    class="ion-no-border"
>
    <ion-toolbar color="primary">
        <cc-toolbar-left
            slot="start"
            [type]="toolbarData.menuButtonType"
            [canGoBack]="toolbarData.canGoBack"
            [showMenuButton]="toolbarData.showMenuButton && shouldShowSidebar"
            (backClick)="onBackClick()"
            (menuButtonClick)="onMenuButtonClick(toolbarData.menuButtonType)"
        >
        </cc-toolbar-left>
        <cc-toolbar-right
            slot="end"
            [popupOpen]="toolbarData.popupOpen"
            [updateAvailable]="updateAvailable$ | async"
            [isOffline]="isOffline$ | async"
            [alertsBadge]="toolbarData.alertsBadge"
            [chatBadge]="toolbarData.chatBadge"
            [loggedInUser]="toolbarData.loggedInUser"
            [canChat]="toolbarData.canChat"
            (alertsClick)="onAlertsClick()"
            (chatClick)="onChatClick(toolbarData.popupOpen)"
            (userClick)="onUserClick()"
            (updateClick)="onUpdateClick()"
        >
        </cc-toolbar-right>
    </ion-toolbar>
</ion-header>
