import { Injectable } from '@angular/core';
import { Breakpoints, BreakpointService } from '@shared/utilities/breakpoint.service';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ChatStorageService } from '@features/chat/services/chat-storage/chat-storage.service';
import { PopupChatState } from '@features/chat/types/popup-chat-state.type';
import { PopupChatView } from '@features/chat/constants/popup-chat-view.constant';
import { ChatService } from '@features/chat/services/chat/chat.service';

@Injectable({
    providedIn: 'root'
})
export class ChatPopupService {
    public triggerOpen = new ReplaySubject<boolean>(1);

    public isOpen$ = combineLatest({
        isOpen: this.triggerOpen,
        usePopup: this.usePopup()
    }).pipe(
        map(({ isOpen, usePopup }) => isOpen && usePopup),
        distinctUntilChanged()
    );

    public state$: Observable<PopupChatState>;

    private stateSubject = new BehaviorSubject<PopupChatState | null>(null);

    constructor(
        private breakpointService: BreakpointService,
        private chatStorageService: ChatStorageService,
        private chatService: ChatService
    ) {
        this.setInitiallyOpen();

        this.isOpen$.subscribe((open) => this.chatStorageService.setShouldUsePopup(open));

        this.state$ = this.stateSubject.asObservable();
    }

    public open(): void {
        this.triggerOpen.next(true);

        this.goToList();
    }

    public close(): void {
        this.triggerOpen.next(false);

        this.stateSubject.next(null);
    }

    public usePopup(): Observable<boolean> {
        return combineLatest([
            this.breakpointService.is(({ width }) => width > Breakpoints.Medium),
            this.chatService.loggedInUserCanChat$()
        ]).pipe(map(([largeDevice, canChat]) => largeDevice && canChat));
    }

    public goToDetail(chatId: string): void {
        this.stateSubject.next({ view: PopupChatView.detail, chatId });
    }

    public goToList(): void {
        this.stateSubject.next({ view: PopupChatView.list });
    }

    private setInitiallyOpen(): void {
        const shouldBeOpenInitially = this.chatStorageService.shouldUsePopup();

        if (shouldBeOpenInitially) {
            this.open();
        }
    }
}
