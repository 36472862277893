import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomRoute } from '@core/routing/types/custom-route';
import { ChatListComponent } from '@features/chat/smart-components/chat-list/chat-list.component';
import { chatPagePopupGuard } from '@features/chat/guards/chat-page-popup-guard/chat-page-popup.guard';

const routes: CustomRoute[] = [
    {
        path: '',
        component: ChatListComponent,
        canActivate: [chatPagePopupGuard()]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UserChatRoutingModule {}
