import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, skip } from 'rxjs';
import { Chat, ChatMessage, People, SlimPeople } from '@api';
import { UntypedFormControl } from '@angular/forms';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChatListPaginationStoreService } from '@features/chat/services/chat-list-pagination-store/chat-list-pagination-store.service';
import { map, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { PeopleApiService } from '@features/people/services/people-api.service';
import { ChatService } from '@features/chat/services/chat/chat.service';
import { ChatPeer } from '@features/chat/types/chat-peer.type';

@Component({
    selector: 'cc-chat-list',
    templateUrl: './chat-list.component.html',
    styleUrls: ['./chat-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ChatListPaginationStoreService]
})
@UntilDestroy()
export class ChatListComponent implements OnInit {
    public chats$: Observable<Chat[]>;
    public loggedInUser$: Observable<People>;
    public initialising$ = new BehaviorSubject(true);
    public potentialChats$: Observable<SlimPeople[]>;
    public hasReachedEnd$: Observable<boolean>;

    public searchInput: UntypedFormControl;

    constructor(
        private authenticationFacadeService: AuthenticationFacadeService,
        private paginationStore: ChatListPaginationStoreService,
        private appCoreFacadeService: AppCoreFacadeService,
        private peopleApiService: PeopleApiService,
        private chatService: ChatService
    ) {}

    public ngOnInit(): void {
        this.loggedInUser$ = this.authenticationFacadeService.getAuthenticatedPerson();

        this.paginationStore.initialise({ page: 1, limit: 25 });
        this.searchInput = this.paginationStore.createAndListenToSearch();
        this.chats$ = this.getChats$();
        this.hasReachedEnd$ = this.paginationStore.hasReachedEnd$;

        this.potentialChats$ = this.getPotentialChats$();

        this.chatService
            .getRealtimeMessages()
            .pipe(withLatestFrom(this.chats$), untilDestroyed(this))
            .subscribe(([realtimeMessage, chats]) => this.onRealtimeMessage(realtimeMessage, chats));
    }

    public hasNewMessage(chat: Chat, loggedInUser: People): boolean {
        return !chat.r_last_message.read_by.includes(loggedInUser.id);
    }

    public getPeer(chat: Chat, loggedInUser: number): ChatPeer {
        return chat.peers.find((p) => p.id !== loggedInUser);
    }

    public createNewChat(personId: number): void {
        this.chatService.startChat(personId);
    }

    public nextPage(): void {
        this.paginationStore.nextPage();
    }

    private getPotentialChats$(): Observable<SlimPeople[]> {
        return combineLatest({
            search: this.searchInput.valueChanges.pipe(startWith('')),
            chats: this.chats$,
            loggedInUser: this.authenticationFacadeService.getAuthenticatedPerson()
        }).pipe(
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([{ search, chats, loggedInUser }, appUrl]) =>
                this.peopleApiService
                    .getAutocompletePaginatedPeople({
                        appUrl,
                        query: {
                            search,
                            page: 0,
                            limit: 20
                        },
                        filters: ['canChat']
                    })
                    .pipe(
                        map((response) =>
                            response.results.filter((potentialChat) => {
                                if (potentialChat.id === loggedInUser.id) {
                                    return false;
                                }
                                return !chats.some((chat) => chat.peers.some((peer) => peer.id === potentialChat.id));
                            })
                        )
                    )
            )
        );
    }

    private getChats$(): Observable<Chat[]> {
        return this.paginationStore.resource$.pipe(
            map((response) =>
                response.results
                    .filter((chat) => chat.peers.length > 1)
                    .sort((a, b) => b.r_last_message.created.localeCompare(a.r_last_message.created))
            ),
            skip(1),
            tap(() => this.initialising$.next(false))
        );
    }

    private onRealtimeMessage(realtimeMessage: ChatMessage, chats: Chat[]): void {
        const chat = chats.find((c: any) => c.id === realtimeMessage.chat);

        if (chat) {
            this.paginationStore.patchItem({
                find: (item: any) => item.id === chat.id,
                newItem: {
                    ...chat,
                    r_last_message: realtimeMessage
                }
            });
        } else {
            this.paginationStore.refreshQuery();
        }
    }
}
