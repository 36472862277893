<ion-item
    class="chat-list-item__item"
    button
    lines="full"
    [ngClass]="{ 'new-message': hasNewMessage }"
    [routerLink]="routerLink"
>
    <div class="chat-list-item__peer-icon" slot="start">
        <cc-person-icon size="small" [person]="peer"></cc-person-icon>
    </div>

    <ion-label>
        <h3 class="chat-list-item__peer">
            <cc-person-name [person]="peer"></cc-person-name>
        </h3>

        <p class="chat-list-item__last-message">
            @if (lastMessageWasSentByMe) {
                {{ 'CHAT_YOU_SENT_LAST' | translate: { message: chat.r_last_message.text } }}
            } @else {
                {{ chat.r_last_message.text }}
            }
        </p>
    </ion-label>

    <div class="chat-list-item__created" slot="end">
        <div class="chat-list-item__created-container">
            <div>
                {{ chat.r_last_message.created | dateFormat: { day: 'numeric', month: 'short' } }}
            </div>
            <div>{{ chat.r_last_message.created | dateFormat: 'time' }}</div>
        </div>
    </div>
</ion-item>
