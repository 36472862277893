import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppCommonModule } from '@common/common.module';
import { ChatModule } from '@features/chat/chat.module';
import { ChatPopupComponent } from '@features/chat/smart-components/chat-popup/chat-popup.component';
import { UserChatModule } from '@features/chat/user-chat-module.module';

@NgModule({
    declarations: [ChatPopupComponent],
    exports: [ChatPopupComponent],
    imports: [AppCommonModule, UserChatModule, ChatModule, RouterModule]
})
export class PopupChatModule {}
