import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { ChatPopupService } from '@features/chat/services/chat-popup/chat-popup.service';
import { map } from 'rxjs/operators';
import { ChatRouteParams } from '@features/chat/constants/chat-route-params.constant';
import { AppCoreActions } from '@core/root-store/store/app-core/actions/app-core.actions';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';

// If the user should be using popup chat, ensure the chat popup is open and navigate to the correct chat page
// Otherwise let it carry through to the dedicated pages
export function chatPagePopupGuard(): CanActivateFn {
    return (route: ActivatedRouteSnapshot) => {
        const chatPopupService = inject(ChatPopupService);
        const appCoreFacadeService = inject(AppCoreFacadeService);
        const router = inject(Router);

        return chatPopupService.usePopup().pipe(
            map((usePopup) => {
                if (!usePopup) {
                    return true;
                }

                chatPopupService.open();

                const chatId = route.paramMap.get(ChatRouteParams.chatId);

                if (chatId) {
                    chatPopupService.goToDetail(chatId);
                } else {
                    chatPopupService.goToList();
                }

                // If the user hasn't previously navigated e.g. they landed on a chat page from an email link
                // then navigate them to the homepage as a fallback
                if (!router.navigated) {
                    appCoreFacadeService.dispatch(AppCoreActions.navigateHome());
                }

                return false;
            })
        );
    };
}
