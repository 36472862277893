@if (
    {
        initialising: initialising$ | async,
        messages: messages$ | async,
        loggedInUser: loggedInUser$ | async,
        peer: peer$ | async
    };
    as data
) {
    @if (data.initialising) {
        <div>
            <cc-loading-spinner></cc-loading-spinner>
        </div>
    }

    @if (!data.initialising) {
        @if (displayHeader && data.peer) {
            <cc-person-header
                class="chat-detail__header"
                [person]="data.peer"
                [actions]="headerActions"
            ></cc-person-header>
        }

        <cc-auto-scroll-bottom class="chat-detail__scroll-container" [items]="chatMessages" [useIonContent]="false">
            @for (message of data.messages; track message.id) {
                @if (isTimeBreak(message, data.messages[$index - 1])) {
                    <div class="chat-detail__time-break">
                        {{ message.created | dateFormat: 'dateAndTime' }}
                    </div>
                }

                <cc-chat-message
                    #chatMessages
                    [message]="message.text"
                    [sentByPeer]="message.sender !== data.loggedInUser.id"
                    [user]="message.sender !== data.loggedInUser.id ? data.peer : data.loggedInUser"
                    @fadeIn
                >
                </cc-chat-message>
            }
        </cc-auto-scroll-bottom>

        <cc-chat-detail-footer (send)="onSend($event)"></cc-chat-detail-footer>
    }
}
