import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { GlobalBannerState } from '@core/banner/store/models/banner.state.model';
import { App } from '@shared/api';
import { slideInOutFromBottom } from '@common/animations/animations';

@Component({
    selector: 'cc-app-layout',
    templateUrl: './app-layout.component.html',
    styleUrls: ['./app-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [slideInOutFromBottom()]
})
export class AppLayoutComponent implements OnChanges {
    @Input()
    public app: App;

    @Input()
    public language: string;

    @Input()
    public hasAccess: boolean;

    @Input()
    public shouldShowToolbar: boolean;

    @Input()
    public shouldShowSidebar: boolean;

    @Input()
    public shouldShowTabbar: boolean;

    @Input()
    public globalBannerConfig: GlobalBannerState;

    @Input()
    public popupChatOpen: boolean;

    @Output()
    public dismissBanner = new EventEmitter<void>();

    public shouldShowBanner;

    public ngOnChanges(): void {
        this.shouldShowBanner =
            this.hasAccess &&
            this.globalBannerConfig.enabled &&
            (!this.globalBannerConfig.dismissable || !this.globalBannerConfig.hasDismissed);
    }
}
