import { Pipe, PipeTransform } from '@angular/core';
import sanitizeHtml from 'sanitize-html';
import { DefaultHtmlSanitiserOptions } from '../constants/default-html-sanitiser-options.constant';

@Pipe({
    name: 'htmlSanitise'
})
export class SanitiserPipe implements PipeTransform {
    transform(value: string): string {
        return sanitizeHtml(value, DefaultHtmlSanitiserOptions);
    }
}
