import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AppUserPageRoutes } from '@core/routing/constants/user-page-routes.constant';
import { Chat } from '@api';
import { ChatPeer } from '@features/chat/types/chat-peer.type';

@Component({
    selector: 'cc-chat-list-item',
    templateUrl: './chat-list-item.component.html',
    styleUrls: ['./chat-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatListItemComponent implements OnChanges {
    @Input()
    public chat: Chat;

    @Input()
    public peer: ChatPeer;

    @Input()
    public hasNewMessage: boolean;

    @Input()
    public lastMessageWasSentByMe: boolean;

    @Output()
    public itemClicked: EventEmitter<void> = new EventEmitter<void>();

    public routerLink: any[];

    public ngOnChanges(): void {
        this.routerLink = ['/', this.chat.app, AppUserPageRoutes.chat, this.chat.id];
    }

    public onItemClicked(): void {
        this.itemClicked.emit();
    }
}
