import { Injectable } from '@angular/core';
import { ActivityFeedApiActions } from '@features/activity-feed/store/actions/activity-feed-api.actions';
import { AlertsActions } from '@features/alerts/store/actions/alerts.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { AgendaSessionApiActions } from '../../../../../features/agenda/agenda-session/store/actions/agenda-session-api.actions';
import { LivePollingApiActions } from '../../../../../features/live-polling/store/actions/live-polling-api.actions';
import { MapDetailActions } from '../../../../../features/map/store/actions/map-detail.actions';
import { PeopleApiActions } from '../../../../../features/people/store/actions/people-api.actions';
import { PortalApiActions } from '../../../../../features/portal/store/actions/portal-api.actions';
import { SurveysApiActions } from '../../../../../features/surveys/store/actions/surveys-api.actions';
import { AppPageRoutes } from '../../../../routing/constants/app-page-routes.constant';
import { AppRoutingActions } from '../../app-routing/actions/app-routing.actions';

@Injectable()
export class ErrorHandlingEffects {
    navigateTo404$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                ...[
                    AgendaSessionApiActions.fetchSessionPageFailure,
                    PeopleApiActions.fetchDetailFailure,
                    LivePollingApiActions.getLivepollPageDataFailure,
                    MapDetailActions.loadFailure,
                    PortalApiActions.getPortalInfoPageDataFailure,
                    SurveysApiActions.getSurveyPageDataFailure,
                    SurveysApiActions.getSurveyCompletePageDataFailure,
                    ActivityFeedApiActions.fetchPostPageFailure,
                    AlertsActions.getDeeplinkFailure
                ]
            ),
            map(() => AppRoutingActions.goToAppPage({ urlSegments: [AppPageRoutes.error404] }))
        )
    );

    constructor(private actions$: Actions) {}
}
