import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { FocusService } from '@core/a11y/services/focus.service';
import { BannerFacadeService } from '@core/banner/services/banner-facade/banner-facade.service';
import { BannerActions } from '@core/banner/store/actions/banner.actions';
import { GlobalBannerState } from '@core/banner/store/models/banner.state.model';
import { NavigationFacadeService } from '@core/navigation/services/navigation-facade.service';
import { NavigationActions } from '@core/navigation/store/actions/navigation.actions';
import { TitleService } from '@core/routing/services/title/title.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { App } from '@shared/api';
import { Observable } from 'rxjs';
import { filter, map, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { AuthenticationFacadeService } from '../../../authentication/services/authentication-facade.service';
import { I18nService } from '../../../i18n/services/i18n.service';
import { AppRoutingActions } from '../../../root-store/store/app-routing/actions/app-routing.actions';
import { AppRoutingFacadeService } from '../../../routing/services/app-routing-facade.service';
import { AppCoreFacadeService } from '../../services/app-core-facade.service';
import { ChatPopupService } from '@features/chat/services/chat-popup/chat-popup.service';

@UntilDestroy()
@Component({
    selector: 'cc-app-page',
    templateUrl: './app-page.component.html',
    styleUrls: ['./app-page.component.scss']
})
export class AppPageComponent implements OnInit, OnDestroy {
    public appInitialising$: Observable<boolean>;
    public appSettings$: Observable<App>;
    public language$: Observable<string>;
    public shouldShowToolbar$: Observable<boolean>;
    public shouldShowTabbar$: Observable<boolean>;
    public shouldShowSidebar$: Observable<boolean>;
    public hasAccess$: Observable<boolean>;
    public globalBannerConfig$: Observable<GlobalBannerState>;
    public popupChatOpen$: Observable<boolean>;
    public navigatingToAnotherApp = false;

    constructor(
        private appCoreFacadeService: AppCoreFacadeService,
        private appRoutingFacadeService: AppRoutingFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private navigationFacadeService: NavigationFacadeService,
        private i18nService: I18nService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private focusService: FocusService,
        private bannerFacadeService: BannerFacadeService,
        private titleService: TitleService,
        private chatPopupService: ChatPopupService
    ) {}

    public ngOnInit(): void {
        this.setupRedirectToHomePage();
        this.setupDetectNavigateToNewApp();

        this.navigationFacadeService.dispatch(NavigationActions.created());

        this.appInitialising$ = this.appCoreFacadeService.getAppInitialising();
        this.appSettings$ = this.appCoreFacadeService.getAppSettings();
        this.language$ = this.i18nService.language$;

        this.hasAccess$ = this.authenticationFacadeService.hasAppAccess();

        this.shouldShowToolbar$ = this.navigationFacadeService.getShouldDisplayToolbar();
        this.shouldShowTabbar$ = this.navigationFacadeService.getShouldDisplayTabbar();
        this.shouldShowSidebar$ = this.navigationFacadeService.getShouldDisplaySidebbar();
        this.globalBannerConfig$ = this.bannerFacadeService.getGlobalBannerConfig();
        this.popupChatOpen$ = this.chatPopupService.isOpen$;

        this.setupFocusMainOnNavigate();
        this.titleService.initialise();
    }

    ngOnDestroy(): void {
        this.navigationFacadeService.dispatch(NavigationActions.destroyed());
    }

    public onDismissBanner(): void {
        this.bannerFacadeService.dispatch(BannerActions.dismissGlobalBanner());
    }

    private setupFocusMainOnNavigate(): void {
        this.router.events
            .pipe(
                untilDestroyed(this),
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute.snapshot),
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route.data;
                }),
                filter((data) => data.focusMainOnNavigate)
            )
            .subscribe(() => {
                this.focusService.focusElementBySelector('main.app__body');
            });
    }

    private setupRedirectToHomePage(): void {
        // Detect when navigation to /{appName} and redirect to app homepage
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationStart),
                map((event: NavigationStart) => event.url),
                startWith(this.router.url),
                // Make sure to wait for the new app settings if navigating to another app.
                switchMap((url) =>
                    this.appCoreFacadeService.getAppSettings().pipe(
                        filter((app) => app && url.includes(app.url_name)),
                        map((app) => ({ url, app }))
                    )
                ),
                untilDestroyed(this)
            )
            .subscribe(({ url, app }) => {
                /**
                 * Remove the query params from the URL so that the comparison below is an accurate check on whether
                 * the user it on the root page of the app
                 */
                const urlWithOutQueryParams = url.split('?')[0];
                const lastSegmentOfUrl = urlWithOutQueryParams.split('/').slice(-1)[0];

                if (lastSegmentOfUrl === app.url_name) {
                    this.appRoutingFacadeService.dispatch(
                        AppRoutingActions.goToAppModule({ urlSegments: [app.homepage.id] })
                    );
                }
            });
    }

    private setupDetectNavigateToNewApp(): void {
        // Detect when navigating to a new app, so we can show the loading spinner until the navigation is complete.
        this.router.events
            .pipe(withLatestFrom(this.appCoreFacadeService.getAppName()), untilDestroyed(this))
            .subscribe(([event, appName]) => {
                if (event instanceof NavigationStart && event.url.split('/')[1] !== appName) {
                    this.navigatingToAnotherApp = true;
                }
                if (event instanceof NavigationEnd) {
                    this.navigatingToAnotherApp = false;
                }
            });
    }
}
