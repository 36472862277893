import { Injectable } from '@angular/core';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { Chat, ChatMessage, People } from '@api';
import { ChatApiService } from '@features/chat/services/chat-api/chat-api.service';
import { distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { LoginPromptService } from '@features/login/services/login-prompt.service';
import { AppUserPageRoutes } from '@core/routing/constants/user-page-routes.constant';
import { AblyMessagingService } from '@core/ably/services/ably-messaging.service';

@Injectable({
    providedIn: 'root'
})
export class ChatService {
    public badgeNumber$: Observable<number>;

    private unreadChatsSubject = new BehaviorSubject<Chat['id'][]>([]);

    constructor(
        private appCoreFacadeService: AppCoreFacadeService,
        private apiService: ChatApiService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private router: Router,
        private loginPromptService: LoginPromptService,
        private ablyMessagingService: AblyMessagingService
    ) {
        this.badgeNumber$ = this.unreadChatsSubject.pipe(map((unreadChats) => unreadChats.length));

        this.getRealtimeMessages().subscribe((message) => {
            if (!this.unreadChatsSubject.value.includes(message.chat)) {
                this.unreadChatsSubject.next([...this.unreadChatsSubject.value, message.chat]);
            }
        });

        this.authenticationFacadeService
            .hasAppAccessAndAuthenticated()
            .pipe(distinctUntilChanged())
            .subscribe(() => this.unreadChatsSubject.next([]));
    }

    public startChat(peer: People['id']): void {
        combineLatest({
            appUrl: this.appCoreFacadeService.getAppName().pipe(take(1)),
            loggedInUser: this.authenticationFacadeService.getAuthenticatedPerson().pipe(take(1))
        })
            .pipe(
                switchMap(({ appUrl, loggedInUser }) => {
                    if (!loggedInUser) {
                        return this.loginPromptService.showPrompt('CHAT_AUTH_REQUIRED');
                    }

                    return this.apiService
                        .createChat(appUrl, peer, loggedInUser.id)
                        .pipe(tap((chat) => this.router.navigate([appUrl, AppUserPageRoutes.chat, chat.id])));
                })
            )
            .subscribe();
    }

    public getRealtimeMessages(): Observable<ChatMessage> {
        return this.ablyMessagingService
            .getMessageStream(['chatmessage'])
            .pipe(map(({ data }) => JSON.parse(data) as ChatMessage));
    }

    public markAsRead(chatId: Chat['id']): void {
        this.unreadChatsSubject.next(this.unreadChatsSubject.value.filter((id) => id !== chatId));

        this.appCoreFacadeService
            .getAppName()
            .pipe(
                take(1),
                switchMap((appUrl) => this.apiService.markRead(appUrl, chatId))
            )
            .subscribe();
    }

    public canChat$(person: { chat_enabled?: boolean; engagement_restricted?: boolean }): Observable<boolean> {
        return this.appCoreFacadeService
            .getAppSettings()
            .pipe(map((appSettings) => appSettings.can_chat && person.chat_enabled && !person.engagement_restricted));
    }

    public loggedInUserCanChat$(): Observable<boolean> {
        return this.authenticationFacadeService
            .getAuthenticatedPerson()
            .pipe(switchMap((person) => (person ? this.canChat$(person) : of(false))));
    }
}
