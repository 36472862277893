import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SanitiserPipe } from './pipes/sanitiser.pipe';

@NgModule({
    declarations: [SanitiserPipe],
    imports: [CommonModule],
    exports: [SanitiserPipe]
})
export class SanitiserModule {}
