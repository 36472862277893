<ng-container
    *ngIf="{
        appInitialising: appInitialising$ | async,
        appSettings: appSettings$ | async,
        language: language$ | async,
        hasAccess: hasAccess$ | async,
        shouldShowToolbar: shouldShowToolbar$ | async,
        shouldShowTabbar: shouldShowTabbar$ | async,
        shouldShowSidebar: shouldShowSidebar$ | async,
        globalBannerConfig: globalBannerConfig$ | async,
        popupChatOpen: popupChatOpen$ | async
    } as pageData"
>
    <cc-app-layout
        *ngIf="!pageData.appInitialising && !navigatingToAnotherApp; else loading"
        [app]="pageData.appSettings"
        [shouldShowToolbar]="pageData.shouldShowToolbar"
        [shouldShowTabbar]="pageData.shouldShowTabbar"
        [shouldShowSidebar]="pageData.shouldShowSidebar"
        [language]="pageData.language"
        [hasAccess]="pageData.hasAccess"
        [globalBannerConfig]="pageData.globalBannerConfig"
        [popupChatOpen]="pageData.popupChatOpen"
        (dismissBanner)="onDismissBanner()"
    >
        <ng-content></ng-content>
    </cc-app-layout>
</ng-container>
<ng-template #loading>
    <cc-loading-spinner></cc-loading-spinner>
</ng-template>
