import { Injectable } from '@angular/core';
import { PersistedPaginationStore } from '@shared/pagination/services/persisted-pagination-store/persisted-pagination-store.service';
import { PaginatedResponse } from '@shared/pagination/models/paginated-response.model';
import { PaginationState } from '@shared/pagination/models/pagination-state.model';
import { Observable } from 'rxjs';
import { ChatApiService } from '@features/chat/services/chat-api/chat-api.service';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { switchMap, take } from 'rxjs/operators';
import { Chat } from '@api';

@Injectable()
export class ChatListPaginationStoreService extends PersistedPaginationStore<Chat> {
    constructor(
        private apiService: ChatApiService,
        private appCoreFacadeService: AppCoreFacadeService
    ) {
        super();
    }

    protected fetchResource(query: PaginationState): Observable<PaginatedResponse<Chat>> {
        return this.appCoreFacadeService.getAppName().pipe(
            take(1),
            switchMap((appName) => this.apiService.getPaginatedChats(appName, query))
        );
    }
}
